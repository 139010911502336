<template>
    <el-dialog
        :title="$t('keys.change_holder')"
        :visible.sync="modalEditHolder"
        :before-close="closeModal"
        width="600px"
        top="40px"
        :close-on-click-modal="false"
        @closed="$emit('closed')"
    >
        <el-row>
            <el-col :span="20" :offset="2">
                <el-form
                    ref="selectedKey"
                    v-loading="$wait.is('loading.employees')"
                    class="key-edit-owner-form"
                    :model="selectedKey"
                    label-position="top"
                    element-loading-spinner="el-custom-spinner"
                >
                    <el-form-item :label="$t('keys.holder')">
                        <el-select v-model="selectedHolder" :placeholder="$t('keys.select_holder')" filterable>
                            <el-option value="office" :label="$t('keys.at_office')" />
                            <el-option value="client" :label="$t('keys.at_client')" />
                            <el-option-group :label="$t('keys.employees')">
                                <el-option
                                    v-for="employee in employees"
                                    :key="employee.uuid"
                                    :label="employee.name + ' ' + employee.surname"
                                    :value="employee.uuid"
                                />
                            </el-option-group>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('keys.replacement')">
                        <el-checkbox v-model="replacement" :disabled="selectedHolder === 'office' || selectedHolder === 'client'">
                            {{ $t(`keys.mark_that_assignment_as_temporary_replacement_system_will_notify_you_if_this_employee_will_not_return_this_key_after_3_days`) }}
                        </el-checkbox>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal">{{ $t('common.cancel') }}</el-button>
            <el-button type="primary" :loading="$wait.is('updating.holder')" @click="updateHolder">{{ $t('common.update') }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props: {
        modalEditHolder: {
            type:    Boolean,
            default: false,
        },
        selectedKey: {
            type: Object,
            default() {
                return {
                    selectedKey: {},
                };
            },
        },
    },

    data() {
        return {
            employees:          [],
            selectedHolder:     null,
            selectedHolderName: null,
            selectedHolderUuid: null,
            replacement:        false,
        };
    },

    computed: {
        holderName() {
            if (this.selectedHolder === 'client' || this.selectedHolder === 'office') {
                return this.selectedHolder;
            }
            const employeeName = this.employees.filter(employee => employee.uuid === this.holderUuid);

            return `${employeeName[0].name} ${employeeName[0].surname}`;
        },
        holderUuid() {
            if (this.selectedHolder === 'client' || this.selectedHolder === 'office') {
                return null;
            }
            return this.selectedHolder;
        },
    },

    watch: {
        selectedHolder() {
            if (this.selectedHolder === 'office' || this.selectedHolder === 'client') {
                this.replacement = false;
            }
        },
    },

    created() {
        if (this.selectedKey.holder === 'client' || this.selectedKey.holder === 'office') {
            this.selectedHolder = this.selectedKey.holder;
        } else {
            this.selectedHolder = this.selectedKey.holder_uuid;
        }

        this.getEmployees();
    },

    methods: {
        async getEmployees() {
            this.$wait.start('loading.employees');

            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/employees/employees`);
            this.employees = data;
            this.$wait.end('loading.employees');
        },

        async updateHolder() {
            this.$wait.start('updating.holder');
            try {
                await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/clients/keys/${this.selectedKey.uuid}`, {
                    replacement: this.replacement,
                    holder:      this.holderName,
                    holder_uuid: this.holderUuid,
                });
                this.$notify.success({ title: this.$t('common.success') });
                this.$wait.end('updating.holder');
                this.closeModal();
                this.$emit('refresh');
            } catch (error) {
                this.$wait.end('updating.holder');
            }
        },

        closeModal() {
            this.$emit('close');
        },
    },
};
</script>
<style>
.key-edit-owner-form .el-select {
    width: 100%;
}

.key-edit-owner-form .el-checkbox {
    display: flex;
    align-items: center;
}

.key-edit-owner-form .el-checkbox__label {
    word-break: break-word;
    white-space: break-spaces;
}
</style>
